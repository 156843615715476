import React from "react";
import UniversalLinkButton from "./UniversalLinkButton";

function LegUpSection() {
  return (
    <section
      className="Index-page"
      data-edit-main-image="Background"
      style={{ background: "#E27264", color: "white" }}
    >
      <div className="Index-page-content sqs-alternate-block-style-container">
        <div className="sqs-layout sqs-grid-12 columns-12" data-type="page">
          <div className="row sqs-row">
            <div className="col sqs-col-12">
              <div
                className="row sqs-row"
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="col sqs-col-6">
                  <div className="sqs-block image-block sqs-block-image sqs-text-ready">
                    <div
                      className="sqs-block-content"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <UniversalLinkButton
                        variant="solid"
                        colour="white"
                        size="large"
                        to="/signup/"
                      >
                        Get Started
                      </UniversalLinkButton>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-6">
                  <div style={{ maxWidth: 400, fontSize: 24 }}>
                    By providing a fun, positive experience where students feel
                    responsible for understanding math concepts, ProblemScape
                    allows students to learn to enjoy math and tackle problems
                    with persistence and motivation.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LegUpSection;
