import React, { ReactNode } from "react";

type TwoColumnTextImageProps = {
  readonly title?: string;
  readonly text: string;
  readonly image: ReactNode;
};

function TwoColumnTextImage({ title, text, image }: TwoColumnTextImageProps) {
  return (
    <section className="Index-page" data-edit-main-image="Background">
      <div className="Index-page-content sqs-alternate-block-style-container">
        <div className="sqs-layout sqs-grid-12 columns-12" data-type="page">
          <div className="row sqs-row">
            <div className="col sqs-col-12">
              {title && (
                <div className="sqs-block html-block sqs-block-html">
                  <div className="sqs-block-content">
                    <h2
                      style={{
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {title}
                    </h2>
                  </div>
                </div>
              )}
              <div
                className="row sqs-row"
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="col sqs-col-6">
                  <div style={{ maxWidth: 400, fontSize: 24 }}>{text}</div>
                </div>
                <div className="col sqs-col-6">
                  <div className="sqs-block image-block sqs-block-image sqs-text-ready">
                    <div className="sqs-block-content">{image}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TwoColumnTextImage;
