import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "~/components/Seo";
import TitleBanner from "~/components/TitleBanner";
import TwoColumnTextImage from "~/components/TwoColumnTextImage";
import StandardTestimonials from "~/components/StandardTestimonials";
import CostBenefits from "~/features/marketing/CostBenefits";
import WhatDoYouGet from "~/features/marketing/WhatDoYouGet";
import LegUpSection from "~/components/LegUpSection";
import LearnHomeVideoIntro from "~/features/marketing/LearnHomeVideoIntro";
import "~/features/marketing/learn-more.css";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/autoplay";

function HomesLearnMorePage() {
  return (
    <div className="Site-inner Site-inner--index">
      <div className="Content-outer">
        <LearnHomeVideoIntro />
        <TwoColumnTextImage
          text="Prevent Summer Learning Loss and help your child acquire a solid foundational understanding in a subject critical for future academic success!"
          image={
            <StaticImage
              src="../../images/arithmenillustrations-06b.png"
              layout="fullWidth"
              alt="I don't remember this"
              placeholder="none"
            />
          }
        />
        <CostBenefits />
        <TitleBanner
          theme="red"
          headline2="New to ProblemScape?"
          buttons={[
            {
              to: "/signup/",
              label: "Get Started",
            },
          ]}
        >
          Sign up for a Parent account so that you can create accounts for your
          students &amp; check on their progress!
        </TitleBanner>
        <WhatDoYouGet />
        <StandardTestimonials imageBackground />
        {/* <Swiper
          slidesPerView={1}
          allowTouchMove={false}
          centeredSlides
          modules={[Autoplay]}
          autoplay={{
            delay: 5_000,
          }}
          loop
        >
          <SwiperSlide>
            
          </SwiperSlide>
          <SwiperSlide> */}
        <TwoColumnTextImage
          text="Give your kid a leg up in their life-long math journey by harnessing the power of our engaging algebra adventure, designed to build strong mathematical foundations and boost confidence in tackling challenging concepts."
          image={
            <StaticImage
              src="../../images/paintball.png"
              layout="fullWidth"
              alt="Paintball game"
              placeholder="none"
            />
          }
        />
        <LegUpSection />
      </div>
    </div>
  );
}

export function Head() {
  return <Seo title="Homes - Learn More" />;
}

export default HomesLearnMorePage;
