import React from "react";
import { Parallax } from "react-parallax";
import UniversalLinkButton from "~/components/UniversalLinkButton";
import emeraloreImage from "~/images/emeralore.png";
import video720 from "~/images/demo-video-720.mp4";
import "./LearnHomeVideoIntro.css";

function LearnHomeVideoIntro() {
  return (
    <Parallax
      className="parallax-banner"
      bgImage={emeraloreImage}
      bgImageAlt="A Game for Robust Learning"
      blur={2}
      strength={300}
    >
      <div className="Index-page-content  sqs-alternate-block-style-container learn-home-video-intro">
        <video loop autoPlay controls muted>
          <source src={video720} type="video/mp4" />
        </video>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
          }}
        >
          <h2
            style={{
              textAlign: "center",
              whiteSpace: "pre-wrap",
              filter: "drop-shadow(0 0 0.75rem black)",
            }}
          >
            A Game for Robust Learning
          </h2>
          <div
            style={{
              maxWidth: 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
          >
            <p>
              An adventure game that has everything students need to master
              algebra, meticulously designed by alumni from Stanford and
              Columbia university, to ensure a robust educational foundation.
            </p>
            <UniversalLinkButton
              to="/signup/"
              variant="solid"
              size="large"
              colour="red"
            >
              Get Started
            </UniversalLinkButton>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default LearnHomeVideoIntro;
