import React from "react";
import { Link } from "gatsby";
import "./CostBenefits.css";
import { StaticImage } from "gatsby-plugin-image";

function CostBenefits() {
  return (
    <section>
      <div className="container">
        <div className="cost-benefits">
          <Link to="/signup/" className="cost-benefit-card">
            <div className="cost-benefits-content">
              <div>
                <StaticImage
                  src="../../images/ps-logo-subtitle.png"
                  alt="ProblemScape"
                  width={165}
                  placeholder="none"
                />
              </div>
              <div>For the game, videos, practices, and reviews</div>
              <div className="plus-item">plus</div>
              <div>A portal to keep track of progress</div>
            </div>
            <ol className="cost-items">
              <li>
                <strong>$80</strong>
                <div>Access for one year</div>
              </li>
              <li>
                <strong>$50</strong>
                <div>Access for six months </div>
              </li>
              <li>
                <strong>$30</strong>
                <div>Access for three months </div>
              </li>
            </ol>
          </Link>
          <ol>
            <li>
              <div className="cost-benefit-num">1</div>
              <div className="cost-benefit-text">
                A self-paced course for algebra that aligns with the curriculum
                covered in middle school.
              </div>
            </li>
            <li>
              <div className="cost-benefit-num">2</div>
              <div className="cost-benefit-text">
                An adventure set in a 3D virtual world to engage and motivate
                your child to play and learn.
              </div>
            </li>
            <li>
              <div className="cost-benefit-num">3</div>
              <div className="cost-benefit-text">
                A cross-platform game that lets your child login from a tablet,
                Chromebook, or any PC.
              </div>
            </li>
            <li>
              <div className="cost-benefit-num">4</div>
              <div className="cost-benefit-text">
                Practice, review, and other learning content integrated with
                game play.
              </div>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
}

export default CostBenefits;
